<template>
  <div>
    <DateTimePickerCircle
      ref="picker"
      :label="label"
      v-model="date"
      :textFieldProps="textFieldProps"
      :datePickerProps="datePickerProps"
      :timePickerProps="timePickerProps"
      dateFormat="dd/MM/yyyy"
      timeFormat="HH:mm"
      :withoutTime="withoutTime"
      :defaultTime="defaultTime"
      :textFieldHeight="textFieldHeight"
      :textFieldBackgroundColor="textFieldBackgroundColor"
      :clearable="clearable"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import moment from "moment";
import DateTimePickerCircle from "./DateTimePickerCircle";
export default {
  name: "DateTimeAdapter",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    defaultTime: {
      type: String,
      required: false,
    },
    rules: {},
    "error-messages": {},
    withoutTime: {
      type: Boolean,
      default: false,
    },
    textFieldHeight: {
      type: String,
    },
    textFieldBackgroundColor: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      dateFormatted: "",
      isoDate: null,
      datePickerProps: {
        locale: "it",
        format: "24hr",
      },
      timePickerProps: {
        format: "24hr",
      },
    };
  },
  mounted() {
    this.date = this.value
      ? moment
          .parseZone(moment(this.value).format("YYYY-MM-DDTHH:mm:ss.000Z"))
          .local()
          .toDate()
      : null;
  },
  watch: {
    value() {
      if (!this.value) this.$refs.picker.clearHandler();
      this.date = this.value
        ? moment
            .parseZone(moment(this.value).format("YYYY-MM-DDTHH:mm:ss.000Z"))
            .local()
            .toDate()
        : null;
    },
    date() {
      const isoDate = this.date ? moment(this.date).format("YYYY-MM-DDTHH:mm:ss.000Z") : null;
      this.$emit("input", isoDate);
    },
  },
  computed: {
    textFieldProps() {
      return {
        rules: this.rules,
        "error-messages": this["error-messages"],
      };
    },
  },
  methods: {},
  components: {
    DateTimePickerCircle,
  },
};
</script>
